.progressContainer {
    padding: 1rem;
    background: #f5f5f5;
    max-width: 800px;
    margin: 0 auto;
}

.monthlyActivity {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.monthlyActivity h4 {
    color: #333;
    margin: 0 0 1rem 0;
    font-weight: 500;
}

.activityIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
}

.iconItem {
    text-align: center;
    flex: 1;
}

.iconItem img,
.iconItem i {
    width: 32px;
    height: 32px;
    margin-bottom: 0.5rem;
    color: #666;
}

.iconItem span {
    font-size: 0.8rem;
    color: #666;
    display: block;
}

.streakSection {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 1rem;
}

.streakHeader {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.streakHeader i {
    color: #ff8b02;
    margin-right: 0.5rem;
    font-size: 1.2rem;
}

.streakHeader span {
    flex: 1;
    color: #666;
    font-size: 0.9rem;
}

.streakHeader strong {
    color: #333;
}

.progressBar {
    height: 8px;
    background: #f0f0f0;
    border-radius: 4px;
    overflow: hidden;
}

.progressInner {
    height: 100%;
    background: linear-gradient(90deg, #ff8b02 0%, #ffd800 100%);
    transition: width 0.3s ease;
}

.pageTitle {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin: 0 0 1rem 0;
}

.statsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
}

.statItem {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
}

.habitGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
}

.habitItem {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
}

.healthStats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.healthItem {
    background: white;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .statsGrid,
    .habitGrid {
        grid-template-columns: 1fr;
    }
    
    .healthStats {
        grid-template-columns: 1fr;
    }
}

.header {
    background: linear-gradient(90deg, #D84315 0%, #FF7043 100%);
    padding: 1rem;
    color: white;
}

.header h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
}

.backButton {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.backButton i {
    margin-right: 0.5rem;
}